import * as React from 'react';
import { Link } from 'gatsby';

export const Button = props => {
  const {
    link,
    label,
    newTab
  } = props;

  const internal = newTab !== true && /^\/(?!\/)/.test(link);
  if (internal) {
    return (
      <div {...props.attributes}>
        <Link className="primary-button" to={link}>{label}</Link>
      </div>
    );
  }
  return (
    <div {...props.attributes}>
      <a className="primary-button" target={newTab ? '_blank' : ''} href={link}>{label}</a>
    </div>
  );
};