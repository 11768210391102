import * as React from 'react';
import Link from '../Link/Link';

// Do not remove this file or modify the code in anyway.
// It may be required by contract.
// Please contact your Olokoo Contact with any questions.
export default props => {
    return (
        <div className="olokoo-credit">
            <span>Made with</span>
            <span className="icon-heart"></span>
            <span>by <Link href={`https://olokoo.com?ref=https://brookebecker.net`} target="_new" className="credit-underline">Olokoo</Link>.</span>
        </div>
    );
};