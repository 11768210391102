import { Builder } from '@builder.io/react';
import { Button } from './Button';

Builder.registerComponent(Button, {
  name: 'Button Link',
  image: 'https://cdn.olokoo.com/builder/icons/simple-q2m-icon.svg',
  noWrap: true,
  inputs: [
    {
      name: 'link',
      type: 'string',
      defaultValue: '/'
    },
    {
        name: 'newTab',
        type: 'boolean',
        defaultValue: false
    },
    {
        name: 'label',
        type: 'string',
        defaultValue: 'Click Me!'
    }
  ]
});
