import { Builder } from '@builder.io/react';
import { SquareHero } from './SquareHero';

Builder.registerComponent(SquareHero, {
  name: 'Square Hero Image',
  image: 'https://cdn.olokoo.com/builder/icons/simple-square-image-icon.svg',
  inputs: [
    {
      name: 'image',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
      required: true,
      defaultValue:
        'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2F52dcecf48f9c48cc8ddd8f81fec63236'
    },
    {
      name: 'color',
      type: 'string',
      defaultValue: '#CBE29E'
    }
  ]
});
