import { Builder } from '@builder.io/react';
import { ArticleList } from './ArticleList';

Builder.registerComponent(ArticleList, {
  name: 'Blog Article List',
  image: 'https://cdn.olokoo.com/builder/icons/simple-q2m-icon.svg',
  inputs: [
    {
      name: 'quantity',
      type: 'number',
      defaultValue: 10
    }
  ]
});
