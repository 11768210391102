import { builder } from '@builder.io/react';
// a set of widgets you can use in the editor, optional.
import '@builder.io/widgets';
/**
 * Import all custom components so you can use in the builder.io editor
 * https://www.builder.io/c/docs/custom-react-components
 */

// Netlify Contact Form
import './components/Contact/Contact.builder';

// Simple Includes
import './components/Simple/ArticleList/ArticleList.builder';
import './components/Simple/OfferingList/OfferingList.builder';
import './components/Simple/Instagram/Feed/Feed.builder';
import './components/Simple/Contact/Contact.builder';

// Client specific custom components
import './components/Client/SquareHero.builder';
import './components/Client/LogoIcon.builder';

import './components/Client/OfferingListCarousel.builder';
import './components/Client/Stars.builder';

// import './components/Client/SimpleCarouselSlide.builder';
// import './components/Client/SimpleCarousel.builder';

import './components/Button/Button.builder';

import config from './config';
builder.init(config.builderAPIKey);
