import * as React from 'react';
import Link from '../Link/Link';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Credit from '../Footer/Credit';
import Copyright from '../Footer/Copyright';

const useStyles = makeStyles(them => ({
    footer: {
        // padding: '40px 0',
        height: '125px',
        // textAlign: 'center',
        backgroundColor: '#000',
        borderTop: '2px solid #282828'
    },
    footerLink: {
        color: '#fff',
        textDecoration: 'none',
        fontWeight: 'bold',
        padding: '0 10px'
    }
  }));

export default props => {
    const classes = useStyles();

    return (
        <>
            <div className={classes.footer}>
                <div className='container-content'>
                    <Grid container>
                        <Grid item xs={6}>
                            <Credit />
                        </Grid>
                        <Grid item xs={6}>
                            <Copyright siteName={props.siteName} />
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
};
