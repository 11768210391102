import React from 'react';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { StaticQuery, graphql } from "gatsby";
import { Image } from '@builder.io/react';
import Link from '../Link/Link';

import { GetFirstImageFromBlocks, GetShortDescriptionFromBlocks } from '../../helpers/BlocksDataHelper';

export const OfferingListCarousel = props => {
  const {
    showDots,
    showArrows,
    infinteScroll,
    autoplay,
    autoplaySpeed,
    desktopSlidesToShow,
    tabletSlidesToShow,
    mobileSlidesToShow,
    limitOfferingsShown,
    quantity
  } = props;

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1000 },
      items: desktopSlidesToShow,
      slidesToSlide: desktopSlidesToShow
    },
    tablet: {
      breakpoint: { max: 999, min: 640 },
      items: tabletSlidesToShow,
      slidesToSlide: tabletSlidesToShow
    },
    mobile: {
      breakpoint: { max: 639, min: 0 },
      items: mobileSlidesToShow,
      slidesToSlide: mobileSlidesToShow
    }
  };

  return (
    <StaticQuery
      query={graphql`
        {
          allBuilderModels {
            offering {
              content
              data {
                url
              }
            }
          }
        }
      `}
      render={data => (
        <>
          <Carousel
            responsive={responsive}
            swipeable={true}
            showDots={!!showDots}
            infinite={!!infinteScroll}
            autoPlay={!!autoplay}
            shouldResetAutoplay={!!autoplay}
            autoPlaySpeed={autoplaySpeed}
            arrows={!!showArrows}
            renderDotsOutside={true}
            dotListClass="q2m-carousel-dots"
            itemClass="q2m-carousel-item-container"
            containerClass="q2m-carousel-container">
              {
                data &&
                data.allBuilderModels &&
                data.allBuilderModels.offering && 
                data.allBuilderModels.offering.length ? ( data.allBuilderModels.offering.slice(0, (limitOfferingsShown === true ? quantity : 1000)).map((offering) => (
                  <Link href={offering.data.url}>
                    <div className="q2m-carousel-item">
                      <div className="q2m-carousel-item-image-container">
                        <Image image={ GetFirstImageFromBlocks(offering.content.data.blocks) } aspectRatio="0.75" lazy />
                      </div>
                      <h3>{ offering.content.name }</h3>
                      <div className="q2m-carousel-item-description" dangerouslySetInnerHTML={{__html: GetShortDescriptionFromBlocks(offering.content.data.blocks)}} />
                    </div>
                  </Link>
                ))) : <h4>No offerings found.</h4>
              }
          </Carousel>
        </>
      )}
      ></StaticQuery>
  );
};
