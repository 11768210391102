import * as React from 'react';
import Link from '../Link/Link';
import { makeStyles } from '@material-ui/core/styles';
import Logo from '../../assets/brooke-becker-logo.svg';

import { useState, useEffect } from 'react';

import { slide as Menu } from 'react-burger-menu';
import { Cross as Hamburger } from 'hamburger-react';

const useStyles = makeStyles(theme => ({
    header: {
        position: 'absolute',
        width: '100%',
        height: '125px',
        padding: '0 20px',
        backgroundColor: '#fff',
        zIndex: 100,
        // boxShadow: '0 4px 12px 0 rgb(0 0 0 / 5%)',
    },
    headerInner: {
        maxWidth: '1000px',
        margin: '0 auto'
    },
    headerLinks: {
        float: 'right',
        margin: '25px -15px',
        [theme.breakpoints.down('md')]: {
            margin: '25px -12px',
        },
        [theme.breakpoints.down('sm')]: {
            margin: '25px -7px',
        },
        [theme.breakpoints.down(350)]: {
            margin: '25px -3px',
        }
    },
    headerLink: {
        position: 'relative',
        color: '#000',
        textDecoration: 'none',
        fontWeight: '600',
        fontSize: '16px',
        display: 'inline-block',
        justifyContent: 'center',
        lineHeight: '75px',
        height: '60px',
        textAlign: 'center',
        margin: '0 15px',
        boxSizing: 'border-box',
        "&.active-link .active-link-underline": {
            position: 'absolute',
            bottom: 0,
            left: '50%',
            marginLeft: '-8px',
            width: '16px',
            height: '6px',
            backgroundColor: '#942622',
            content: ''
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '13px',
            margin: '0 12px',
            height: '58px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
            margin: '0 7px',
            height: '56px',
        },
        [theme.breakpoints.down(350)]: {
            fontSize: '11px',
            margin: '0 3px',
            height: '54px',
        }
    },
    logo: {
        marginTop: '35px'
    }
  }));

export default props => {
    const classes = useStyles();

    const [isMenuOpen, setMenuOpen] = useState(false);

    const closeMenu = function() {
        setMenuOpen(false);
    };

    return (
        <>
            <div className={classes.header}>
                <div className={classes.headerInner}>
                    <Link href="/">
                        <Logo className={classes.logo} />
                    </Link>
                    <div className={classes.headerLinks + ' header-links'}>
                        {props.links.map((link, index) => (
                            <Link className={ classes.headerLink + ' no-hover' } href={ link.url } partiallyActive={ link.url !== '/' ? true : false }>
                                { link.label }
                                <div className="active-link-underline"></div>
                            </Link>
                        ))}
                    </div>
                    <div className="hamburger-menu-container">
                        <div className={ 'hamburger-button-container' + (isMenuOpen ? ' expanded' : '') }>
                        {/* <div className="hamburger-button-container" style={{height: headerHeight + 'px', lineHeight: headerHeight + 'px'}}> */}
                            <Hamburger
                                toggled={ isMenuOpen }
                                toggle={ setMenuOpen }
                                distance="lg"
                                label="Show menu"
                                hideOutline={ true }
                            />
                        </div> 
                        <Menu
                            right
                            isOpen={ isMenuOpen }
                            customCrossIcon={ false }
                            customBurgerIcon={ false }
                            onClose={ closeMenu }
                            width={ '80%' }>
                            <div className="hamburger-menu-link-container" onClick={ closeMenu }>
                                {props.links.map((link, index) => (
                                    <Link className="hamburger-menu-link" key={ index } href={ link.url } partiallyActive={ link.url !== '/' ? true : false }>
                                        <span className="pretty-underline">{ link.label }</span>
                                    </Link>
                                ))}
                            </div>
                        </Menu>
                    </div>
                </div>
            </div>
        </>
    );
};
