import { Builder } from '@builder.io/react';
import { Stars } from './Stars';

Builder.registerComponent(Stars, {
  name: 'Testimonial Stars',
  image: 'https://cdn.olokoo.com/builder/icons/simple-q2m-icon.svg',
  defaultStyles: {
    marginTop: '0px',
    marginBottom: '10px',
  },
  inputs: [
    {
      name: 'size',
      type: 'number',
      defaultValue: '40'
    },
    {
        name: 'rating',
        type: 'number',
        defaultValue: '5'
    }
  ]
});