import { Builder } from '@builder.io/react';
import { LogoIcon } from './LogoIcon';

Builder.registerComponent(LogoIcon, {
  name: 'Logo Icon',
  image: 'https://cdn.olokoo.com/builder/icons/simple-heart-logo-icon.svg',
  inputs: [
    {
      name: 'width',
      type: 'string',
      defaultValue: '90px'
    },
    {
      name: 'icon',
      type: 'boolean',
      defaultValue: false
    }
  ]
});
