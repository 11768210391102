import React from 'react';
import { Image } from '@builder.io/react';
import { Parallax, Background } from 'react-parallax';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

export const SquareHero = props => {
  const {
    image,
    color
  } = props;

  return (
    <Box className="square-hero">
      <Box className="square-hero-background" style={{ backgroundColor: color }}></Box>
      <Box className="square-hero-image" style={{ backgroundImage: `url(${image})` }}></Box>
    </Box>
  );
};
