import { Builder } from '@builder.io/react';
import { Contact } from './Contact';

Builder.registerComponent(Contact, {
  name: 'Contact Form',
  image: 'https://cdn.olokoo.com/builder/icons/simple-q2m-icon.svg',
  inputs: [
    {
      name: "interestedInHeading",
      type: "text",
      defaultValue: "I'm interested in..."
    },
    {
      name: "interestedOptions",
      type: "list",
      subFields: [{ name: "option", type: "string" }],
    },
    {
      name: "formInputs",
      type: "list",
      subFields: [
        { name: "name", type: "string" },
        { name: "placeholder", type: "text" },
        { name: "type", type: "string", enum: ['text', 'email', 'phone'] }
      ],
    },
    {
      name: "submitButtonLabel",
      type: "text",
      defaultValue: "Send requests"
    }
  ]
});
