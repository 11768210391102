import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Grid from '@material-ui/core/Grid';
import Link from '../../Link/Link';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';

import { GetFirstImageFromBlocks, GetShortDescriptionFromBlocks } from '../../../helpers/BlocksDataHelper';

export const OfferingList = props => {
  const {
    quantity
  } = props;

  const useStyles = makeStyles(theme => ({
    offeringImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
    },
    offeringImageWrapper: {
      width: '100%',
      height: '100%'
    },
    offeringName: { 
      fontSize: '36px',
      marginTop: '-10px'
    },
    offeringBlurb: {
      fontSize: '16px'
    }
  }));
  const classes = useStyles();

  return (
    <StaticQuery
      query={graphql`
        {
          allBuilderModels {
            offering {
              content
              data {
                url
              }
            }
          }
        }
      `}
      render={data => (
        <>
          <Grid container spacing={5} className="offering-list-row">
            {
              data &&
              data.allBuilderModels &&
              data.allBuilderModels.offering && 
              data.allBuilderModels.offering.length ? ( data.allBuilderModels.offering.slice(0, (quantity || 9)).map((offering) => (
                <>
                  <Grid item xs={12} sm={4}>
                    <Link href={offering.data.url}>
                      <div className="offering-list-image">
                        <Skeleton className='lazy-load-image-skeleton' variant="rectangular" />
                        <LazyLoadImage
                          src={GetFirstImageFromBlocks(offering.content.data.blocks)}
                          effect="opacity"
                          className={classes.offeringImage}
                          wrapperClassName={classes.offeringImageWrapper}
                        />
                      </div>
                      <div className="offering-list-content">
                        <div className="offering-name"> { offering.content.name } </div>
                        <div className="content-text" dangerouslySetInnerHTML={{__html: GetShortDescriptionFromBlocks(offering.content.data.blocks)}}></div>
                      </div>
                    </Link>
                  </Grid>
                </>
            ))) : <h4>No offerings found.</h4>
          }
          </Grid>
        </>
      )}
    ></StaticQuery>
  );

};