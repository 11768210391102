import { Builder } from '@builder.io/react';
import { OfferingListCarousel } from './OfferingListCarousel';

Builder.registerComponent(OfferingListCarousel, {
  name: 'Offreings Carousel',
  defaultStyles: {
    marginTop: '0px',
  },
  image: 'https://cdn.olokoo.com/builder/icons/simple-carousel-icon.svg',
  inputs: [
    {
      name: 'limitOfferingsShown',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'quantity',
      friendlyName: 'Limit offerings shown to',
      type: 'number',
      defaultValue: 9,
      showIf: (options, parent, parentElements) => {
        return options.get('limitOfferingsShown');
      }
    },
    {
      name: 'showDots',
      friendlyName: 'Show Navigation Dots',
      type: 'boolean',
      defaultValue: true
    },
    {
      name: 'showArrows',
      friendlyName: 'Show Navigation Arrows',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'infinteScroll',
      friendlyName: 'Infinitely Loop Carousel',
      type: 'boolean',
      defaultValue: true
    },
    {
      name: 'autoplay',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'autoplaySpeed',
      friendlyName: 'Autoplay Delay in milliseconds',
      type: 'number',
      defaultValue: 2000,
      showIf: (options, parent, parentElements) => {
        return options.get('autoplay');
      }
    },
    {
      name: 'desktopSlidesToShow',
      friendlyName: 'Desktop Slides to Show',
      enum: [1,2,3,4,5,6],
      defaultValue: 3
    },
    {
      name: 'tabletSlidesToShow',
      friendlyName: 'Tablet Slides to Show',
      enum: [1,2,3,4,5,6],
      defaultValue: 2
    },
    {
      name: 'mobileSlidesToShow',
      friendlyName: 'Mobile Slides to Show',
      enum: [1,2,3,4,5,6],
      defaultValue: 1
    },
  ]
});
