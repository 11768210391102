import React from 'react';
import Logo from '../../assets/brooke-becker-logo.svg';
import Icon from '../../assets/brooke-becker-icon.svg';

export const LogoIcon = props => {
  const {
    width,
    icon
  } = props;

  var element;
  if (icon) {
    element = <Icon style={{ width: width }} />;
  } else {
    element = <Logo style={{ width: width }} />;
  }

  return (
    <>
      { element }
    </>
  );
};
