import { Builder } from '@builder.io/react';
import { Feed } from './Feed';

Builder.registerComponent(Feed, {
  name: 'Simple Instagram Feed',
  image: 'https://cdn.olokoo.com/builder/icons/simple-instagram-feed-icon.svg',
  inputs: [
    {
      name: 'oat',
      type: 'string',
      defaultValue: 'null'
    },
    {
      name: 'uuid',
      type: 'string',
      defaultValue: 'null'
    }
  ]
});
