function loopChildren(children, type) {
    for (let index = 0; index < children.length; index++) {
        const child = children[index];
        
        if (type === 'image') {
            if (child.component?.name === 'Image') {
                return child.component.options?.image;
                break;
              }
        } else if (type === 'description') {
            if (child.layerName === 'Heading Short Description') {
                return child.component?.options?.text;
                break;
            }
        }

        if (child.children) {
            return loopChildren(child.children, type);
        }

        if (child.component?.name === 'Columns') {
            const columns = child.component.options?.columns;
            for (let index = 0; index < columns.length; index++) {
                const column = columns[index];
                console.log('column:', column);
                var loopResult = loopChildren(column.blocks, type);
                if (loopResult) {
                    return loopResult;
                }
            }
        }
    }
}

module.exports.GetFirstImageFromBlocks = function(blocks) {
    for (let index = 0; index < blocks.length; index++) {
        const block = blocks[index];
    
        if (block.component?.name === 'Image') {
          return block.component.options?.image;
          break;
        }
    
        if (block.children) {
            return loopChildren(block.children, 'image');
        }
    }
}

module.exports.GetShortDescriptionFromBlocks = function(blocks) {
    for (let index = 0; index < blocks.length; index++) {
        const block = blocks[index];
    
        if (block.layerName === 'Heading Short Description') {
            return block.component?.options?.text;
            break;
        }
    
        if (block.children) {
            return loopChildren(block.children, 'description');
        }
    }
}