import { Builder } from '@builder.io/react';
import { Contact } from './Contact';

Builder.registerComponent(Contact, {
  name: 'Simple Contact Form',
  image: 'https://cdn.olokoo.com/builder/icons/simple-q2m-icon.svg',
  defaultStyles: {
    marginTop: '0px',
  },
  inputs: [
    {
      name: "olokooAccessToken",
      type: "text",
      defaultValue: "Enter Access Token Here"
    },
    {
      name: "integrationUUID",
      type: "text",
      defaultValue: "Enter Integration UUID Here"
    },
    {
      name: "honeyPotFieldName",
      type: "text",
      defaultValue: "bot-field",
      description: 'Field name used to trap bots and protect your site from spam.'
    },
    {
      name: "redirectSuccessURL",
      type: "text",
    },
    {
      name: "redirectErrorURL",
      type: "text",
    },
    {
      name: "interestedInHeading",
      type: "text",
      defaultValue: "I'm interested in..."
    },
    {
      name: "interestedOptions",
      type: "list",
      subFields: [{ name: "option", type: "string" }],
      defaultValue: [
        { option: 'Service #1' },
        { option: 'Service #2' },
        { option: 'Service #3' },
        { option: 'Service #4' },
      ]
    },
    {
      name: "formInputs",
      type: "list",
      defaultValue: [
        {
          name: 'email',
          placeholder: 'Your email',
          type: 'email'
        },
        {
          name: 'name',
          placeholder: 'Your name',
          type: 'text'
        },
        {
          name: 'message',
          placeholder: 'Your message',
          type: 'text'
        }
      ],
      subFields: [
        { name: "name", type: "string" },
        { name: "placeholder", type: "text" },
        { name: "type", type: "string", enum: ['text', 'email', 'phone'] }
      ],
    },
    {
      name: "submitButtonLabel",
      type: "text",
      defaultValue: "Send message"
    }
  ]
});
