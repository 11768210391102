import { createMuiTheme } from '@material-ui/core';
import green from '@material-ui/core/colors/green';

export default createMuiTheme({
  overrides: {
    MuiBackdrop: {
      invisible: {
        backgroundColor: 'transparent',
        backdropFilter: 'none'
      },
      root: {
        backdropFilter: 'blur(2px)'
      }
    }
  },
  typography: {
    fontFamily: '"Roboto Serif", serif'
  },
  palette: {
    primary: { main: '#000' },
    secondary: green,
    background: {
      default: '#fff'
    }
  }
});
