import React from 'react';
import Star from '../../assets/star.svg';

export const Stars = props => {
  const {
    size,
    rating
  } = props;

  return (
    <div className="stars" data-star-count={rating}>
        {Array.from({ length: rating }, (_, i) => <Star style={{ width: size }} key={i} />)}
    </div>
  );
};
