import React from 'react';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useState } from 'react';

export const Contact = props => {
  const {
    interestedOptions,
    interestedInHeading,
    formInputs,
    submitButtonLabel
  } = props;

  const theme = useTheme();

  const useStyles = makeStyles(theme => ({
    logo: {
      width: 100,
    },
  }));

  var initialInputValues = {};
  formInputs.forEach(input => {
    initialInputValues[input.name] = '';
  });

  const [inputValues, setInputValues] = useState(initialInputValues);
  const [formReady, setFormReady] = useState(false);
  const [selectedInterested, setSelectedInterested] = useState([]);

  const handleInterestedChange = event => {
    const { name, id, value, checked } = event.target;
    if (!selectedInterested.includes(value)) {
      setSelectedInterested([...selectedInterested, value]);
    } else if (checked === false) {
      setSelectedInterested([...selectedInterested.filter(word => word !== value)]);
    }
  };

  const handleOnChange = event => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
    
    const isFormReady = () => {
      var response = true;
      Object.keys(inputValues).forEach(function(key) {
          if (inputValues[key] === '') {
            response = false;
          }
      });
      return response;
    };

    setFormReady(isFormReady);
  };

  const classes = useStyles();

  return (
    <form action="/contact/successful" name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="contact" />
      <input type="hidden" name="interested" placeholder="Interested In" value={ selectedInterested && selectedInterested.join(', ') } />
      <Box className="interested-in-options-wrap">
        <Box className="interested-in-heading">{ interestedInHeading }</Box>
        {interestedOptions && interestedOptions.map((value, key) => (
          <Box className="interested-in-option-item" key={ key }>
            <input
              type="checkbox"
              className="option-checkbox"
              id={`optionCheckbox${key}`}
              value={ value.option }
              onChange={ handleInterestedChange } /> 
            <label htmlFor={`optionCheckbox${key}`}>{ value.option }</label> 
          </Box>
        ))}
      </Box>
      <Box>
        {formInputs && formInputs.map((value, key) => (
          <Box className="floating-label-input-group" key={ key }>
            <input
              required={ true }
              placeholder={ value.placeholder }
              type={ value.type }
              name={ value.name }
              id={ value.name }
              autoComplete="off"
              onChange={ handleOnChange } />
            <label htmlFor={ value.name }>
              { value.placeholder }
            </label>
          </Box>
        ))}
      </Box>
      <Box>
        <button typle="submit" disabled={ !formReady } className={`minimal-underline-button ${!formReady && 'disabled'}`}>{ submitButtonLabel }</button>
      </Box>
    </form>
  );
};
